
import {defineComponent} from "vue";
import TheHeader from "@/components/TheHeader.vue";
import NavigatorLine from "@/components/NavigatorLine.vue";
import TheFooter from "@/components/TheFooter.vue";
import FlightBookingSteps from "@/components/FlightBooking/Steps.vue";
import FlightPaymentDetail from "@/components/FlightPayment/Detail.vue";
import FlightBookingContactInfo from "@/components/FlightBooking/ContactInfo.vue";
import FlightBookingPassengerList from "@/components/FlightBooking/PassengerList.vue";
import FlightBookingPaymentSelect from "@/components/FlightBooking/PaymentType.vue";
import FlightPaymentExtraInfo from "@/components/FlightPayment/ExtraInfo.vue";
import FlightBookingPaymentPriceChange from "@/components/FlightBooking/PaymentPriceChange.vue";
import Loader from "@/components/General/Loader.vue";
import PaymentForm from "@/components/General/PaymentForm.vue";
import {Passenger} from "@/models/order_modes";
import {ResponseStatusType} from "@/constants/response_status_type";
import {User} from "@/models/auth/user_models";
import {LocalDb} from "@/repository/localdb";
import {SearchParameter} from "@/models/search_parameters";
import {AvioneMessage} from "@/constants/error_messages";
import {OrderData} from "@/models/stored_data_models";
import Swal from "sweetalert2";
import {PaymentService} from "@/services/payment_service";
import {FlightPaymentData} from "@/models/payment_models";
import moment from "moment";

export default defineComponent({
  name: "FlightBookingPayment",
  components: {
    TheHeader,
    FlightBookingSteps,
    NavigatorLine,
    FlightPaymentDetail,
    FlightBookingContactInfo,
    FlightBookingPassengerList,
    FlightBookingPaymentSelect,
    FlightPaymentExtraInfo,
    FlightBookingPaymentPriceChange,
    PaymentForm,
    Loader,
    TheFooter,
  },
  data() {
    return {
      validate: false,
      loading: false,
      loadingMessage: "",
      isLoginOpen: false,
      priceChangeShow: false,
      openPaymentPopup: false,
      paymentUrl: "",
      currentUser: {} as User | null,
      passengers: [] as Passenger[],
      paymentData: {} as FlightPaymentData,
      paymentType: null,
      // ticketBooking: {} as TicketBooking,
      orderData: {} as OrderData | undefined,
      searchParameter: {} as SearchParameter | null,
      amountFromUserBalance: 0,
      orderEndTimeSeconds: 0,
    };
  },

  created() {
    this.currentUser = LocalDb.getCurrentUser();
    this.searchParameter = LocalDb.getSearchParameters();

    const pnrNumber = this.$route.params.id;
    if (pnrNumber) {
      const order: OrderData | undefined = LocalDb.getMyFlightOrder(
          pnrNumber.toString()
      );
      if (order) {
        this.orderData = order;
        this.passengers = order.passengers;
        let expireDateString = this.orderData.timeTillTicket.orderAvioneExpireDateLocal;
        let expireDate = Date.parse(expireDateString);
        this.orderEndTimeSeconds = Math.floor((expireDate - new Date().getTime()) / 1000);
      } else {
        window.location.href = "/";
      }
    }
    if (this.orderEndTimeSeconds > 0) {
      this.onDecreaseOrderEndTime();
    } else {
      this.orderEndTimeSeconds = 0;
    }
  },
  methods: {
    getFormattedEndTime: function () {
      return moment
          .utc(
              moment.duration(this.orderEndTimeSeconds, "seconds").asMilliseconds()
          )
          .format("mm:ss");
    },
    onDecreaseOrderEndTime: function () {
      setInterval(() => {
        this.orderEndTimeSeconds--;
      }, 1000);
    },
    onPaymentFormCloseEventHandler: function () {
      this.openPaymentPopup = false;
      this.paymentUrl = "";
      // if (this.orderData)
      //   window.location.href = "/flight/payment/confirm/" + this.orderData.orderId;
    },
    onContinuePriceChangeClickEventHandler: async function () {
      this.priceChangeShow = false;
      await this.onPaymentClickEventHandler();
    },
    onClosePriceChangeClickEvent: function () {
      this.priceChangeShow = false;
      this.$router.push({
        path: "/profile/orders",
        params: {
          lang: this.$route.params.lang,
        },
      });
    },
    onPaymentTypeChangeEventHandler: function (value: any) {
      this.paymentType = value.selectedPaymentType;
      this.amountFromUserBalance = value.amountFromUserBalance;
    },

    onPaymentClickEventHandler: async function () {
      if (!this.paymentType) {
        await Swal.fire(
            "",
            this.$t(AvioneMessage.PAYMENT_TYPE_CHOOSE),
            "error"
        );
        return;
      }
      if (this.paymentType === "notEnoughInBalance") {
        await Swal.fire("", this.$t(AvioneMessage.NotEnoughInBalance), "error");
        return;
      }
      if (this.orderData == undefined) {
        await Swal.fire("", this.$t(AvioneMessage.ORDER_NOT_FOUND), "error");
        return;
      }
      this.loading = true;
      this.loadingMessage = this.$t(
          AvioneMessage.PAYMENT_TYPE_AVIPAY_REDIRECTION
      );

      const paymentData = {
        orderId: this.orderData.orderId,
        buyId: this.orderData.buyId,
        amountFromUserBalance: this.amountFromUserBalance,
        requiredOnlinePayment:
            parseInt(this.orderData?.amount) - this.amountFromUserBalance,
        paymentMethod: this.paymentType,
      };

      if (this.paymentType === "Balance") {
        await PaymentService.getPaymentFromBalance({
          orderId: this.orderData?.orderId,
        }).then((response) => {
          this.loading = false;
          this.loadingMessage = "";
          if (response.status == "Ok") {
            window.location.href = "/profile/orders";
          } else if (response.status == 3) {
            this.priceChangeShow = true;
            this.paymentData = response.data;
            if (this.orderData) {
              this.orderData.amount = response.data.newPrice;
            } else {
              Swal.fire("Body error", response.data.message, "error");
            }
          } else {
            Swal.fire("Network error", response.message, "error");
          }
        });
      }

      await PaymentService.getPaymentData(paymentData).then((response) => {
        this.loading = false;
        this.loadingMessage = "";
        if (response.status == ResponseStatusType.Error) {
          if (response.data.success_url) {
            window.location.href = response.data.success_url;
          } else if (response.data.status == 3) {
            this.priceChangeShow = true;
            this.paymentData = response.data;
            if (this.orderData) {
              this.orderData.amount = response.data.newPrice;
            }
          } else {
            Swal.fire("Body error", response.data.message, "error");
          }
        } else {
          Swal.fire("Network error", response.message, "error");
        }
      });
    },
    onCancelPaymentRequest: function () {
      LocalDb.removeBookingTicket();
      this.redirectToHome();
    },
    redirectToHome: function () {
      window.location.href = "/";
    },
  },
});
